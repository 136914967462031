/* eslint-disable array-callback-return */
import React, { Fragment, Component } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import './contato-detalhe.css'
import iconDrogaleste from '../assets/icons/all/image.png'
import FotoExemplo from '../assets/images/all/foto-exemplo-loja.svg'
import TagManager from 'react-gtm-module'
import { GatsbyImage } from 'gatsby-plugin-image'
// import phoneIcon from '../assets/icons/all/icon-bt-fale-conosco.svg'
import Whatsapp from '../assets/icons/wpp.svg'

export default class ContatoDetalhe extends Component {
  constructor (props) {
    super(props)

    this.state = {
      map: null,
      localizacaoUsuario: '',
      MarcadorClicado: {},
      isMobile: ''
    }
    this.iniciaMapa = this.iniciaMapa.bind(this)
  }

  componentDidMount () {
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
      const insercao = document.getElementsByTagName('script')[0]
      insercao.parentNode.insertBefore(script, insercao)
      script.addEventListener('load', e => {
        this.iniciaMapa()
      })
    } else {
      this.iniciaMapa()
    }

    window.localStorage.setItem('currentStore', JSON.stringify(this.props.data.Lojas))
  }

  iniciaMapa () {
    const Endereco = this.props.data.Lojas
    const map = new window.google.maps.Map(document.getElementById('mapaDetalhe'), {
      center: { lat: parseFloat(Endereco.lat), lng: parseFloat(Endereco.lng) },
      zoom: 14,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false
    })
    const icon = {
      url: iconDrogaleste, // url
      scaledSize: new window.google.maps.Size(40, 40) // scaled size
    }
    const marcador = new window.google.maps.Marker({
      position: { lat: parseFloat(Endereco.lat), lng: parseFloat(Endereco.lng) },
      map: map,
      icon: icon
    })

    console.log(marcador)
  }

  ligacaotell (idLoja) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ligar',
        eventAction: 'telefone',
        eventLabel: idLoja
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  ligacaowpp (idLoja) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ligar',
        eventAction: 'whatsapp',
        eventLabel: idLoja
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  googlemaps (localizacaoUsuario) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'lojasPopup',
        eventAction: 'localizador',
        eventLabel: localizacaoUsuario
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  generateHours (isOpen, Open, Close) {
    const arr = []
    const offarr = []
    const horarios = [{ id: 1, day: 'monday', text: 'Segunda' }, { id: 2, day: 'tuesday', text: 'Terça' }, { id: 3, day: 'wednesday', text: 'Quarta' }, { id: 4, day: 'thursday', text: 'Quinta' }, { id: 5, day: 'friday', text: 'Sexta' }, { id: 6, day: 'saturday', text: 'Sabado' }, { id: 7, day: 'sunday', text: 'Domingo' }]
    horarios.map((hor, index) => {
      if (this.props.data.Lojas[hor.day + isOpen]) {
        if (arr.findIndex(x => x.hora === this.props.data.Lojas[hor.day + Open].substr(0, 5) + 'h ás ' + this.props.data.Lojas[hor.day + Close].substr(0, 5) + 'h') !== -1) {
          const index = arr.findIndex(x => x.hora === this.props.data.Lojas[hor.day + Open].substr(0, 5) + 'h ás ' + this.props.data.Lojas[hor.day + Close].substr(0, 5) + 'h')
          arr[index].dia.push(hor.text)
        } else {
          arr.push({ hora: this.props.data.Lojas[hor.day + Open].substr(0, 5) + 'h ás ' + this.props.data.Lojas[hor.day + Close].substr(0, 5) + 'h', dia: [hor.text] })
        }
      } else {
        if (offarr.findIndex(x => x.hora === this.props.data.Lojas[hor.day + Open].substr(0, 5) + 'h ás ' + this.props.data.Lojas[hor.day + Close] + 'h') !== -1) {
          const index = offarr.findIndex(x => x.hora === this.props.data.Lojas[hor.day + Open].substr(0, 5) + 'h ás ' + this.props.data.Lojas[hor.day + Close] + 'h')
          offarr[index].dia.push(hor.text)
        } else {
          offarr.push({ hora: this.props.data.Lojas[hor.day + Open].substr(0, 5) + 'h ás ' + this.props.data.Lojas[hor.day + Close].substr(0, 5) + 'h', dia: [hor.text] })
        }
      }
    })
    return { func: arr, semfunc: offarr }
  }

  verifyExist (isOpen) {
    const horarios = [{ id: 1, day: 'monday', text: 'Segunda' }, { id: 2, day: 'tuesday', text: 'Terça' }, { id: 3, day: 'wednesday', text: 'Quarta' }, { id: 4, day: 'thursday', text: 'Quinta' }, { id: 5, day: 'friday', text: 'Sexta' }, { id: 6, day: 'saturday', text: 'Sabado' }, { id: 7, day: 'sunday', text: 'Domingo' }]
    let cont = 0
    horarios.map((hor, index) => {
      if (this.props.data.Lojas[hor.day + isOpen]) {
        cont = cont + 1
      }
    })

    return cont !== 0
  }

  render () {
    const horariofuncionamento = this.generateHours('IsOpen', 'Open', 'Close')
    const horariodelivery = this.generateHours('IsOpenDelivery', 'OpenDelivery', 'CloseDelivery')
    const funcionamento = horariofuncionamento.func
    const semfuncionamento = horariofuncionamento.semfunc
    const funcionamentoDelivery = horariodelivery.func
    const semfuncionamentoDelivery = horariodelivery.semfunc

    // const regex = /[^0-9]+/g

    const BtnsMobile = () => {
      return (
        <div id="contact" className="col-md-12">
          <div className="d-flex d-sm-none flex-column">
            {/* <div className="d-flex flex-column">
              {this.props.data.Lojas.whatsapp !== null ? <a href={`https://wa.me/55${this.props.data.Lojas.whatsapp.replace(regex, '')}`} title="Whatsapp da loja" id="btn-wpp" className="btn theme--secundary text-white theme-border-2 w-100 mb-2" target=" _blank" onClick={() => this.ligacaowpp(this.props.data.Lojas.id)}><span className="wpp-icon pt-1" style={{ marginLeft: '10px' }}></span><span className='pl-lg-3 d-none d-md-inline'>Disk Entrega</span><span className="pl-lg-4">{this.props.data.Lojas.whatsapp}</span></a> : ''}
              {this.props.data.Lojas.fone1 !== null ? <a href={`tel:+55${this.props.data.Lojas.fone1.replace(regex, '')}`} title="Telefone 1 da loja" className="btn theme--secundary text-white theme-border-2 w-100 Tel1 mb-2" onClick={() => this.ligacaotell(this.props.data.Lojas.id)}><img src={phoneIcon} width={30}></img> <span className='pl-lg-3 d-none d-md-inline'>Disk Entrega</span><span className="pl-lg-4">{this.props.data.Lojas.fone1}</span></a> : ''}
              {this.props.data.Lojas.fone2 !== null ? <a href={`tel:+55${this.props.data.Lojas.fone2.replace(regex, '')}`} title="Telefone 2 da loja" className="btn theme--secundary text-white theme-border-2 w-100 Tel2 mb-2" onClick={() => this.ligacaotell(this.props.data.Lojas.id)}><img src={phoneIcon} width={30}></img> <span className='pl-lg-3 d-none d-md-inline'>Disk Entrega</span><span className="pl-lg-4">{this.props.data.Lojas.fone2}</span></a> : ''}
            </div> */}
          </div>
        </div>
      )
    }

    const RedeSocias = () => {
      return (
        <div className="col-md-12 mt-4 mt-md-2">
          <h3 className="theme-primary">Siga nas redes sociais</h3>
          <ul className="list-inline social-media">
            <li className="list-inline-item"><a title="Facebook DrogaLeste" href="https://www.facebook.com/drleste" className="btn"><span className="sr-only">Facebook</span></a></li>
            <li className="list-inline-item"><a title="Instagram DrogaLeste" href="https://www.instagram.com/drogaleste" className="btn"><span className="sr-only">Instagram</span></a></li>
          </ul>
        </div>
      )
    }

    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <html lang="pt" />
          <title>{`${this.props.data.Lojas.name} - ${this.props.data.Lojas.storeNumber}`} | Drogaleste</title>
          <meta name="title" content={`${this.props.data.Lojas.name}`} />
          <meta name="author" content="Drogaleste" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={`${this.props.data.Lojas.name}`} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`https://www.drogaleste.com.br/lojas/${this.props.data.Lojas.slug}/contato/`} />
          {this.props.data.Lojas.image &&
            <meta
              property="og:image"
              content={`https://www.drogaleste.com.br${this.props.data.Lojas.image.publicURL}`}
            />
          }
          <meta property="og:description" content="Drogaleste" />
        </Helmet>
        <a href='https://api.whatsapp.com/send?=pt_BR&phone=5511947796762'>
        <div className=''>
          <div className='drogaleste-container position-fixed' role='button'>
            <div className='drogaleste-theme d-flex'>
            </div>
            <img src={Whatsapp} alt='Whatsapp' className='botao-fixo-zap' crossOrigin='anonymous'/>
          </div>
        </div>
      </a>
        <section>
          <div className="container pt-4">
            <div id="about-store" className="row">
              <div className="col-md-12">
                <h1 className="theme-primary"> <img src={iconDrogaleste} width="30" style={{ paddingBottom: '8px' }} />&nbsp;{this.props.data.Lojas.name} - {this.props.data.Lojas.storeNumber}</h1>
              </div>
              <div className="col-md-6 no-gutters">
                <div className="col-md-12 d-block d-sm-none mb-2">
                  {this.props.data.Lojas.image &&
                    <GatsbyImage
                      image={this.props.data.Lojas.image.childImageSharp.gatsbyImageData}
                      alt="Foto da Loja" />
                  }
                  {!this.props.data.Lojas.image ? <img id="store-picture" src={FotoExemplo} className="img-fluid rounded foto-padrao" title="Foto da loja DrogaLeste" alt="Foto Loja DrogaLeste" /> : ''}
                </div>
                <BtnsMobile />
                <div className='col-md-12'>
                  {this.verifyExist('IsOpenDelivery') &&
                    <>
                      <h3 className='theme-primary mt-4'>Disk Entrega</h3>
                      {funcionamentoDelivery.map((func, index) => {
                        // eslint-disable-next-line no-return-assign
                        return (
                          <div key={index}>
                            {func.dia.length === 7
                              ? `Todos os Dias: ${func.hora}`
                              : <>
                                {func.dia.includes('Segunda') && func.dia.includes('Terça') && func.dia.includes('Quarta') && func.dia.includes('Quinta') && func.dia.includes('Sexta') && func.dia.includes('Sabado')
                                  ? `Segunda até Sábado: ${func.hora}`
                                  : func.dia.join(' - ') + `: ${func.hora}`}</>
                            }
                          </div>
                        )
                      })}
                      {semfuncionamentoDelivery.map((semfunc, index) => {
                        // eslint-disable-next-line no-return-assign
                        return (
                          <div key={index}>
                            {semfunc.dia.join(' - ') + ': Não Entrega'}
                          </div>
                        )
                      })}
                      Feriados: {this.props.data.Lojas.holidayIsOpenDelivery ? `${this.props.data.Lojas.holidayOpenDelivery.substr(0, 5)}h ás ${this.props.data.Lojas.holidayCloseDelivery.substr(0, 5)}h` : 'Não Entrega'}
                    </>
                  }
                </div>
                <div className="col-md-12">
                  <h3 className='theme-primary mt-4'>Endereço</h3>
                  <address>
                    {this.props.data.Lojas.address} - {this.props.data.Lojas.district}<br />
                    {this.props.data.Lojas.city} - {this.props.data.Lojas.uf} - {this.props.data.Lojas.cep}
                  </address>
                </div>
                <div className='col-md-12'>
                  <h3 className='theme-primary mt-4'>Horário De Funcionamento</h3>
                  {funcionamento.map((func, index) => {
                    // eslint-disable-next-line no-return-assign
                    return (
                      <div key={index}>
                        {func.dia.length === 7
                          ? `Todos os Dias: ${func.hora}`
                          : <>
                            {func.dia.includes('Segunda') && func.dia.includes('Terça') && func.dia.includes('Quarta') && func.dia.includes('Quinta') && func.dia.includes('Sexta') && func.dia.includes('Sabado')
                              ? `Segunda até Sábado: ${func.hora}`
                              : func.dia.join(' - ') + `: ${func.hora}`}</>
                        }
                      </div>
                    )
                  })}
                  {semfuncionamento.map((semfunc, index) => {
                    // eslint-disable-next-line no-return-assign
                    return (
                      <div key={index}>
                        {semfunc.dia.join(' - ') + ': Não Abre'}
                      </div>
                    )
                  })}
                  Feriados: {this.props.data.Lojas.holidayIsOpen ? `${this.props.data.Lojas.holidayOpen.substr(0, 5)}h ás ${this.props.data.Lojas.holidayClose.substr(0, 5)}h` : 'Não Entrega'}
                </div>
                <div className='col-md-12 mt-4'>
                  <h3 className='theme-primary'>Serviços</h3>
                  <ul className='list-inline services'>
                    {this.props.data.Lojas.services.map(servico => {
                      return (
                        <img
                          src={servico.icon}
                          alt={`${servico.name} disponível na loja.`}
                          className='mostra-thumbnail-detalhes'
                          key={servico.name}
                          data-toogle="tooltip" title={servico.name} />
                      )
                    })}
                  </ul>
                </div>
                <div id="contact" className="col-md-12 mt-4 d-none d-sm-flex flex-column">
                  <h3 className="theme-primary">Contato</h3>
                  <p>Para dúvidas, seguestões ou informações, fale com a loja.</p>
                  {/* {console.log(this.props.data.Lojas)} */}
                  {/* <div className="d-flex flex-column"> */}
                    {/* {this.props.data.Lojas.fone1 !== null ? <a href={`tel:+55${this.props.data.Lojas.fone1.replace(regex, '')}`} title="Telefone 1 da loja" className="btn theme--secundary theme-border-2 w-100 Tel1 mb-2" onClick={() => this.ligacaotell(this.props.data.Lojas.id)}><img src={phoneIcon} width={30}></img> <span className='pl-3 d-none d-md-inline text-white'>Disk Entrega</span><span className="pl-4 text-white">{this.props.data.Lojas.fone1}</span></a> : ''}
                    {this.props.data.Lojas.fone2 !== null ? <a href={`tel:+55${this.props.data.Lojas.fone2.replace(regex, '')}`} title="Telefone 2 da loja" className="btn theme--secundary theme-border-2 w-100 Tel2 mb-0" onClick={() => this.ligacaotell(this.props.data.Lojas.id)}><img src={phoneIcon} width={30}></img> <span className='pl-3 d-none d-md-inline text-white'>Disk Entrega</span><span className="pl-4 text-white">{this.props.data.Lojas.fone2}</span></a> : ''}
                    {this.props.data.Lojas.whatsapp !== null ? <a href={`https://wa.me/55${this.props.data.Lojas.whatsapp.replace(regex, '')}`} title="Whatsapp da loja" id="btn-wpp" className="btn theme--secundary text-white theme-border-2 w-100 mb-2" target=" _blank" onClick={() => this.ligacaowpp(this.props.data.Lojas.id)}><span className="wpp-icon pt-1" style={{ marginLeft: '10px' }}></span><span className='pl-3 d-none d-md-inline'>Disk Entrega</span><span className="pl-4">{this.props.data.Lojas.whatsapp}</span></a> : ''} */}
                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-6 no-gutters">
                <div className="col-12 d-none d-md-block">
                  {this.props.data.Lojas.image &&
                    <GatsbyImage
                      image={this.props.data.Lojas.image.childImageSharp.gatsbyImageData}
                      alt="Foto da Loja" />
                  }
                  {!this.props.data.Lojas.image ? <img id="store-picture" src={FotoExemplo} className="img-fluid rounded foto-padrao" title="Foto da loja DrogaLeste" alt="Foto Loja DrogaLeste" /> : ''}
                </div>
                <div className='d-none d-sm-flex'>
                  <RedeSocias />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="theme-primary mt-4">COMO CHEGAR</h3>
                <div style={{ position: 'relative' }}>
                  <div id="mapaDetalhe" style={{ width: '100%', height: '300px' }} />
                </div>
              </div>
              <div className="col-md-12">
                <br />
              </div>
              <div className="col-md-6 ">
                <a target='_blank' rel='noreferrer noopener' href={`https://www.google.com/maps/dir/Minha+Localização/${this.props.data.Lojas.lat},${this.props.data.Lojas.lng}`} className="w-100 btn btn-google-maps theme--primary text-white" title="Como chegar até a loja, utilizando o Google Maps" onClick={() => this.googlemaps(this.localizacaoUsuario)}><span>GOOGLE MAPS</span></a>
              </div>
              <div className="col-md-6">
                <a href={`waze://?ll=${this.props.data.Lojas.lat},${this.props.data.Lojas.lng}&navigate=yes`} className="w-100 btn icon-waze theme--primary d-md-none" title="Como chegar até a loja, utilizando o Waze"><span className="sr-only">WAZE</span></a>
              </div>
              <div className="col-md-12">
                <br />
              </div>
            </div>
            <div className='d-flex d-sm-none'>
              <RedeSocias />
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
export const query = graphql`
query($slug: String!) {
  Lojas: cloudOfertasLoja(slug: { eq: $slug }) {
    id
    slug
    storeNumber
    address
    cep
    city
    district
    lat
    lng
    name
    fone1
    fone2
    reference
    status
    uf
    whatsapp
    image {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
      publicURL
    }
    services {
      icon
      name
    }
    mondayOpen
    mondayIsOpen
    mondayClose
    holidayOpen
    holidayIsOpen
    holidayClose
    fridayOpen
    fridayIsOpen
    fridayClose
    tuesdayOpen
    tuesdayIsOpen
    tuesdayClose
    thursdayOpen
    thursdayIsOpen
    thursdayClose
    wednesdayOpen
    wednesdayIsOpen
    wednesdayClose
    saturdayIsOpen
    saturdayClose
    saturdayOpen
    sundayOpen
    sundayIsOpen
    sundayClose
    mondayOpenDelivery
    mondayIsOpenDelivery
    mondayCloseDelivery
    holidayOpenDelivery
    holidayIsOpenDelivery
    holidayCloseDelivery
    fridayOpenDelivery
    fridayIsOpenDelivery
    fridayCloseDelivery
    tuesdayOpenDelivery
    tuesdayIsOpenDelivery
    tuesdayCloseDelivery
    thursdayOpenDelivery
    thursdayIsOpenDelivery
    thursdayCloseDelivery
    wednesdayOpenDelivery
    wednesdayIsOpenDelivery
    wednesdayCloseDelivery
    saturdayIsOpenDelivery
    saturdayCloseDelivery
    saturdayOpenDelivery
    sundayOpenDelivery
    sundayIsOpenDelivery
    sundayCloseDelivery
  }
}
`
